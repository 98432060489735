import { InjectionToken, NgModule } from '@angular/core';

import { ILogicalUnitModalRoute } from '../../gamma/logicalunit/logicalunit-modal.interface';

import { U2000_AuthenticationGuard } from '../U2000_core/U2000_guards/U2000_authentication.guard';
import { U2026_NetPromoterScoreFormComponent } from './U2026_netpromoterscore-modal/U2026_netpromoterscore-form/U2026_netpromoterscore-form.component';
import { U2026_NetPromoterScoreModalComponent } from './U2026_netpromoterscore-modal/U2026_netpromoterscore-modal.component';
import { U2026_NetPromoterScoreResolver } from './U2026_netpromoterscore-modal/U2026_netpromoterscore.resolver';
import { U2026_NetPromoterScoreService } from './U2026_netpromoterscore.service';
import { U2026_ScoreQuestionComponent } from './U2026_score-question/U2026_score-question.component';

export const U2026_NetPromoterScoreRoute: ILogicalUnitModalRoute<U2026_NetPromoterScoreService> = {
    component: U2026_NetPromoterScoreModalComponent,
    resolver: U2026_NetPromoterScoreResolver,
    canActivate: [U2000_AuthenticationGuard],
};

export const U2026_Route = new InjectionToken<ILogicalUnitModalRoute<U2026_NetPromoterScoreService>>('U2026_Route');

@NgModule({
    imports: [],
    exports: [],
    providers: [{ provide: U2026_Route, useValue: U2026_NetPromoterScoreRoute }],
})
export class U2026_NetPromoterScoreRoutingModule {}

export const U2026_RoutedComponents = [U2026_NetPromoterScoreModalComponent, U2026_NetPromoterScoreFormComponent, U2026_ScoreQuestionComponent];
