import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormQuestionBaseComponent } from '../../../gamma/form/formquestion/formquestion-base.component';
import { FormRadioQuestion, IFormRadioButton } from '../../../gamma/form/formquestion/formquestion-radio';
import { I18nService } from '../../../gamma/i18n';
import { U2026_NetPromoterScoreService } from '../U2026_netpromoterscore.service';

@Component({
    selector: 'u2026-score-question',
    templateUrl: './U2026_score-question.component.html',
    styleUrls: ['./U2026_score-question.component.scss'],
})
export class U2026_ScoreQuestionComponent extends FormQuestionBaseComponent implements AfterViewInit {
    @Input() override question: FormRadioQuestion<any>;
    @Input() override form: UntypedFormGroup;
    @Output() editEvent = new EventEmitter<IFormRadioButton<string>>();
    @Output() addEvent = new EventEmitter<string>();

    constructor(i18nService: I18nService, private el: ElementRef, public service: U2026_NetPromoterScoreService) {
        super(i18nService);
    }

    ngAfterViewInit() {
        if (this.question.onAfterViewInit) {
            this.question.onAfterViewInit(this.el.nativeElement);
        }
    }

    onChange($event, radioButton: IFormRadioButton<string>) {
        if (this.question.onChange != null) {
            this.question.onChange($event, this.question);
        }
    }
}
