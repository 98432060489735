import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { IFromControlValidationError } from '../../../../gamma/core/components/base.component';
import { FormBaseComponent } from '../../../../gamma/form/form-base.component';
import { FormService } from '../../../../gamma/form/form.service';
import { FormCheckboxQuestion } from '../../../../gamma/form/formquestion/formquestion-checkbox';
import { FormValidationMessageService } from '../../../../gamma/form/formvalidationmessage.service';
import { ValidationMessages } from '../../../../gamma/form/validationmessages';
import { LocalStorageService } from '../../../../gamma/localstorage';
import { ILogicalUnitModalRoute } from '../../../../gamma/logicalunit/logicalunit-modal.interface';
import { ModalService } from '../../../../gamma/modal';
import { RoutingService } from '../../../../gamma/routing';
import { ToastService } from '../../../../gamma/toast';
import { DomService } from '../../../../gamma/utils/dom.service';
import { U2000_AuthenticationService, U2000_ValidationMessages } from '../../../U2000_core';
import { U2000LS_LastLoggedUsername } from '../../../U2000_core/U2000_localstorages';
import { U2000_LogService } from '../../../U2000_core/U2000_services/U2000_log.service';
import { U2000_LoginPasswordQuestion, U2000_LoginUsernameQuestion } from '../../../U2000_questiondomains';
import { U2026_Route } from '../../../U2026_netpromoterscore/U2026_netpromoterscore-routing.module';
import { U2026_NetPromoterScoreService } from '../../../U2026_netpromoterscore/U2026_netpromoterscore.service';
import { AppInitService } from '../../../app-init.service';
import { U2011_LoginService } from '../../U2011_login.service';

@Component({
    selector: 'u2011-login-form',
    templateUrl: 'U2011_login-form.component.html',
    providers: [FormService],
    styleUrls: ['U2011_login-form.component.scss'],
})
export class U2011_LoginFormComponent extends FormBaseComponent<U2011_LoginService> implements OnInit, OnDestroy {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'Login';

    qUsername: U2000_LoginUsernameQuestion;
    qPassword: U2000_LoginPasswordQuestion;
    qRememberMe: FormCheckboxQuestion;
    logForTenantError = false;
    logForTenantErrorMessage: string;

    constructor(
        service: U2011_LoginService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,
        domService: DomService,

        appInitService: AppInitService,
        localStorageService: LocalStorageService,
        public authService: U2000_AuthenticationService,
        public logService: U2000_LogService,
        private modalService: ModalService,
        private injector: Injector,
        @Inject(U2026_Route) private u2026_Route: ILogicalUnitModalRoute<U2026_NetPromoterScoreService>,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        const lastLoggedUsername = localStorageService.get<string>(U2000LS_LastLoggedUsername, String.empty).trim();

        this.qUsername = new U2000_LoginUsernameQuestion({
            defaultValue: lastLoggedUsername,
            onInput: (event: Event) => {
                if (!String.isNullOrEmpty(this.qPassword.formControlRef.value)) {
                    this.qPassword.formControlRef.setErrors(null);
                }
                if (String.isNullOrEmpty(this.qUsername.formControlRef.value)) {
                    this.qUsername.formControlRef.markAsPristine();
                }
            },
        });

        this.qPassword = new U2000_LoginPasswordQuestion({
            onInput: (event: Event) => {
                if (!String.isNullOrEmpty(this.qUsername.formControlRef.value)) {
                    this.qUsername.formControlRef.setErrors(null);
                }
                if (String.isNullOrEmpty(this.qPassword.formControlRef.value)) {
                    this.qPassword.formControlRef.markAsPristine();
                }
            },
        });

        this.qRememberMe = new FormCheckboxQuestion({
            key: 'rememberMe',
            alternateLabel: 'lblRememberMe',
        });

        this.questions = [this.qUsername, this.qPassword];

        if (!String.isNullOrEmpty(appInitService.initData.mappingWorkflowReference)) {
            this.questions.push(this.qRememberMe);
        }

        this.authService.authenticated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(loggedIn => {
            if (!this.form) {
                return null;
            }
            if (loggedIn) {
                this.form.disable();

                if (this.authService.userProfile.displayNPS && !this.authService.userProfile.promptSecurityInfo) {
                    setTimeout(() => {
                        const params = this.modalService.routeToParams(this.u2026_Route, this.injector);
                        this.modalService.openLogicalUnit<U2026_NetPromoterScoreService, number>(params);
                    }, 1000);
                }
            } else {
                this.form.enable();
            }
        });
    }

    ngOnInit() {
        this.initForm({ skipFormGuardIfDirty: true });
        if (this.authService.isLoggedIn) {
            this.qUsername.disabled = true;
            this.qPassword.disabled = true;
            this.form.disable();
        }
    }

    override ngOnDestroy() {
        super.ngOnDestroy();
        this.destroyForm();
    }

    onSubmit() {
        this.service.clearAlerts();
        this.authService.cleanLoginInfo();
        this.showErrors(this.form.controls);

        if (this.form.valid) {
            this.authService.getToken(this.form.value).subscribe(
                (tokenRes: IU2000_CoreCurrentAgentConsolidatedInfoDto) => {
                    this.resetFormState();

                    return this.service.onGetToken(tokenRes);
                },
                (res: HttpErrorResponse) => {
                    if (res.error.error === 'invalid_grant') {
                        let message = U2000_ValidationMessages.invalidUsernamePassword_0010.message;
                        if (res.error.error_description) {
                            message = res.error.error_description;
                        }
                        this.qUsername.formControlRef.setErrors(ValidationMessages.noMessage);
                        this.qPassword.formControlRef.setErrors(ValidationMessages.noMessage);
                        this.service.addAlert({ type: 'danger', message: message });
                    } else {
                        this.handleServerValidationError(res.error, (validationError: IErrorElement, formControlErr: IFromControlValidationError): boolean => {
                            this.service.addAlert({ type: 'danger', message: validationError.message });
                            return true;
                        });
                    }
                },
            );
        }
    }
}
