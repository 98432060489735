import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { Mixin } from '../../../../gamma/core';
import { FormBaseComponent } from '../../../../gamma/form/form-base.component';
import { FormService } from '../../../../gamma/form/form.service';
import { FormValidationMessageService } from '../../../../gamma/form/formvalidationmessage.service';
import { I18nService } from '../../../../gamma/i18n';
import { RoutingService } from '../../../../gamma/routing';
import { ToastService } from '../../../../gamma/toast';
import { DomService } from '../../../../gamma/utils';

import { Validators } from '@angular/forms';
import { FormRadioQuestion } from '../../../../gamma/form/formquestion/formquestion-radio';
import { FormTextareaQuestion } from '../../../../gamma/form/formquestion/formquestion-textarea';
import { U2000_AlertComponentMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-component.mixin';
import { U2000_AlertServiceMixin } from '../../../U2000_core/U2000_mixins/U2000_alert-service.mixin';
import { U2026_NetPromoterScoreService } from '../../U2026_netpromoterscore.service';
import { U2026_NetPromoterScoreContext } from '../U2026_netpromoterscore-modal.component';

@Component({
    selector: 'u2026-netpromoterscore-form',
    templateUrl: 'U2026_netpromoterscore-form.component.html',
    styleUrls: ['U2026_netpromoterscore-form.component.scss'],
    providers: [FormService],
})
@Mixin([U2000_AlertComponentMixin])
export class U2026_NetPromoterScoreFormComponent extends FormBaseComponent<U2026_NetPromoterScoreService> implements OnInit, OnDestroy, U2000_AlertComponentMixin {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'NetPromoterScore';

    @Input() userContext: U2026_NetPromoterScoreContext;
    @Output() completeEvent = new EventEmitter<any>();
    @Output() cancelEvent = new EventEmitter<any>();

    //#region Mixin requirements
    // Alert mixin requirements
    addAlertSubscription: Subscription;
    updateAlertSubscription: Subscription;
    clearAlertSubscription: Subscription;
    initAlertWithoutService: () => void;
    linkAlertWithService: (service: U2000_AlertServiceMixin) => void;
    destroyAlertComponent: () => void;
    alertClosed: (alert: IU2000_Alert) => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    alerts: IU2000_Alert[];
    //#endregion

    qScore: FormRadioQuestion<number>;
    qComment: FormTextareaQuestion;

    constructor(
        public override service: U2026_NetPromoterScoreService,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        validationMessageService: FormValidationMessageService,

        // Alert mixin requirements
        public i18nService: I18nService,
        public override domService: DomService,
    ) {
        super(service, toastService, routingService, validationMessageService, domService);

        this.linkAlertWithService(this.service);
    }

    ngOnInit() {
        this.qScore = new FormRadioQuestion({
            key: 'score',
            label: 'lblRecommendToFriends',
            radioButtons: [
                { key: '0', value: 0, label: '0' },
                { key: '1', value: 1, label: '1' },
                { key: '2', value: 2, label: '2' },
                { key: '3', value: 3, label: '3' },
                { key: '4', value: 4, label: '4' },
                { key: '5', value: 5, label: '5' },
                { key: '6', value: 6, label: '6' },
                { key: '7', value: 7, label: '7' },
                { key: '8', value: 8, label: '8' },
                { key: '9', value: 9, label: '9' },
                { key: '10', value: 10, label: '10' },
            ],
            validators: [Validators.required],
        });

        this.qComment = new FormTextareaQuestion({
            key: 'comment',
            label: 'lblPrincipalReasonForNote',
            placeholder: 'lblOptional',
        });

        this.questions = [this.qScore, this.qComment];
        this.initForm();
    }

    override ngOnDestroy() {
        this.destroyForm();
        this.destroyAlertComponent();
    }

    onSubmit() {
        this.showErrors(this.form.controls);
        if (this.form.valid) {
            let request: IU2026_SaveScoreDtoRequest = {
                score: this.qScore.value,
                comment: this.qComment.value,
            };
            this.service.saveScore(request).subscribe(
                res => {
                    this.toastService.success('lblScoreSaved');
                    this.completeEvent.emit();
                },
                err => {
                    this.service.addAlert({ type: 'danger', message: err.error.errors[0].message });
                },
            );
        }
    }

    onCancel() {
        this.resetForm();
        this.cancelEvent.emit();
    }
}
