import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

import { Mixin } from '../../gamma/core';
import { FormBaseComponent, IRegistrationFormConfigs } from '../../gamma/form/form-base.component';
import { FormMixin, IRegisteredForm } from '../../gamma/form/form.mixin';
import { IFormValidationMessage } from '../../gamma/form/formvalidationmessage.service';
import { HttpErrorService, HttpService } from '../../gamma/http';
import { RoutingService } from '../../gamma/routing';

import { TranslateService } from '@ngx-translate/core';
import { map, Observable, share } from 'rxjs';
import { LogicalUnitInitMixin, LogicalUnitModalBaseService } from '../../gamma/logicalunit';
import { SecurityService } from '../../gamma/security';
import { ToastService } from '../../gamma/toast';
import { U2000_TranslatePartialLoader, U2000_TranslateServiceMixin } from '../U2000_core';
import { U2000_AlertServiceMixin } from '../U2000_core/U2000_mixins/U2000_alert-service.mixin';

@Injectable()
@Mixin([FormMixin, U2000_AlertServiceMixin, U2000_TranslateServiceMixin, LogicalUnitInitMixin])
export class U2026_NetPromoterScoreService extends LogicalUnitModalBaseService implements FormMixin, U2000_AlertServiceMixin, U2000_TranslateServiceMixin, LogicalUnitInitMixin {
    // There to avoid using the constructor's name that is not available when minified.
    static override className = 'U2026_NetPromoterScoreService';

    // InitLU mixin requirements
    luConfigs: any; // IU2201_InitDto;
    initLU: () => Observable<any>;
    // Form mixin requirements
    formValidationMessages: IFormValidationMessage[];
    registeredForms: IRegisteredForm[];
    registerForm: (form: FormBaseComponent, configs: IRegistrationFormConfigs) => void;
    unregisterForm: (form: FormBaseComponent) => void;
    hasDirtyForms: () => boolean;
    cleanupForms: () => void;
    // Alert mixin requirements
    alertsAdded: IU2000_Alert[];
    alertAdded$: EventEmitter<IU2000_Alert>;
    alertUpdated$: EventEmitter<IU2000_Alert>;
    alertsCleared$: EventEmitter<any>;
    initAlert: () => void;
    addAlert: (alert: IU2000_Alert) => void;
    updateAlert: (alert: IU2000_Alert) => void;
    clearAlerts: () => void;
    // Translate mixin requirements
    initTranslation: () => Observable<any>;
    //#endregion

    constructor(
        public override routingService: RoutingService, //

        // InitLU mixin requirements
        public http: HttpClient,
        public override httpService: HttpService,
        public httpErrorService: HttpErrorService,
        public toastService: ToastService,
        public translateService: TranslateService,
        // Translate mixin requirements
        public translatePartialLoader: U2000_TranslatePartialLoader,

        public securityService: SecurityService,
    ) {
        super(routingService, httpService);

        this.formValidationMessages = [];

        //this.translationParts = ['U2026_NetPromoterScore'];

        this.initAlert();

        this.logicalUnitCreated();
    }

    saveScore(request: IU2026_SaveScoreDtoRequest) {
        const url = `${this.apiUrl}/saveScore`;
        return this.http.post(url, request).pipe(
            map(res => {
                const response = res as IU2026_SaveScoreDtoResponse;
                return response;
            }),
            share(),
        ) as Observable<any>;
    }

    skipSurvey() {
        const url = `${this.apiUrl}/skipSurvey`;
        return this.http.post(url, null).pipe(share()) as Observable<any>;
    }
}
