import { Component } from '@angular/core';

import { DialogRef, ModalComponent } from 'ngx-modialog-7';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';

import { ToastService } from '../../../gamma/toast';

import { LogicalUnitModalBaseComponent } from '../../../gamma/logicalunit';
import { ModalDismissGuard } from '../../../gamma/modal/modal-dismiss.guard';
import { RoutingService } from '../../../gamma/routing';
import { U2026_NetPromoterScoreService } from '../U2026_netpromoterscore.service';

export type U2026_NetPromoterScoreContext = 'ElectronicCheque' | 'PortalPayer';

export interface IU2026_NetPromoterScoreModalContext extends BSModalContext {
    userContext: U2026_NetPromoterScoreContext;
}

@Component({
    selector: 'u2000-netpromoterscore-modal',
    templateUrl: 'U2026_netpromoterscore-modal.component.html',
    styleUrls: ['U2026_netpromoterscore-modal.component.scss'],
})
export class U2026_NetPromoterScoreModalComponent extends LogicalUnitModalBaseComponent<U2026_NetPromoterScoreService> implements ModalComponent<BSModalContext> {
    context: IU2026_NetPromoterScoreModalContext;

    userContext: U2026_NetPromoterScoreContext;

    constructor(
        service: U2026_NetPromoterScoreService,
        // ModalComponent requirements
        public dialog: DialogRef<BSModalContext>,
        // Super requirements
        toastService: ToastService,
        routingService: RoutingService,
        modalDismissGuard: ModalDismissGuard,
    ) {
        super(service, dialog, toastService, routingService, modalDismissGuard);

        this.context = dialog.context as IU2026_NetPromoterScoreModalContext;

        this.context.isBlocking = true;
        this.context.showClose = true;

        this.userContext = this.context.userContext;

        this.context.dialogClass = 'modal-dialog u2026_netpromoterscore-modal';
    }

    onComplete() {
        this.dialog.close(true);
    }

    closeClick() {
        this.service.skipSurvey().subscribe(
            res => {
                this.dialog.close(false);
            },
            err => {
                this.dialog.close(false);
            },
        );
    }

    onCancel() {
        this.closeClick();
    }
}
