import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { BusyIndicatorService } from '../../../gamma/busyindicator';
import { LogicalUnitResolver } from '../../../gamma/logicalunit';
import { U2026_NetPromoterScoreService } from '../U2026_netpromoterscore.service';

@Injectable()
export class U2026_NetPromoterScoreResolver extends LogicalUnitResolver<U2026_NetPromoterScoreService> implements Resolve<LogicalUnitResolver<U2026_NetPromoterScoreService>> {
    constructor(service: U2026_NetPromoterScoreService, busyIndicatorService: BusyIndicatorService) {
        super(service, busyIndicatorService);
    }
}
