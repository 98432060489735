import { NgModule } from '@angular/core';

import { FormModule } from '../../gamma/form/form.module';

import { U2000_FormValidationProvider } from '../U2000_core';
import { U2000_SharedModule } from '../U2000_shared';
import { U2026_NetPromoterScoreResolver } from './U2026_netpromoterscore-modal/U2026_netpromoterscore.resolver';
import { U2026_NetPromoterScoreRoutingModule, U2026_RoutedComponents } from './U2026_netpromoterscore-routing.module';
import { U2026_NetPromoterScoreService } from './U2026_netpromoterscore.service';

@NgModule({
    imports: [
        U2026_NetPromoterScoreRoutingModule,
        U2000_SharedModule,
        FormModule.withConfig({
            provider: U2000_FormValidationProvider,
        }),
    ],
    exports: [],
    declarations: [U2026_RoutedComponents],
    providers: [U2026_NetPromoterScoreService, U2026_NetPromoterScoreResolver],
})
export class U2026_NetPromoterScoreModule {}
