export const languageHashValues= {
  "GLOBAL": ".aef4e25e4317f2fe7765",
  "U4302_TRANSACTIONSDETAIL": ".e8beaaf2eac729d817ac",
  "U4400_PAYMENT": ".c0d265c2a437f9969c7c",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".4fac12f318fa320d6aaa",
  "U2117_PADAGREEMENTINVITATION": ".7ecc274545bec4575f70",
  "U2000_PAD": ".d0ef73eeea28abc44fff",
  "U4402_INVOICEPORTALPAYMENT": ".548e4bc496a0cc92b27c",
  "U2000_BANKACCOUNTCREATION": ".0856da7f8505984e8d7e",
  "U2011_LOGIN": ".c44ba566159a3de789fd",
  "U2012_LOGINHELP": ".99396bd89109e6cf34c4",
  "U2014_SECURITY": ".e18d477fd035ea150ac1",
  "U2086_EFTPENDINGPAYMENTS": ".00cde6fb60a30fc662b6",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".27e5ced493968e3f07bf",
  "U2110_ACCOUNTCREATION": ".21dcaaa23870014f957f",
  "U2121_INDIVIDUALPROFILE": ".61a14ccbd84ae6c1df96",
  "U2131_ORGANIZATIONPROFILE": ".41d8a1edcf0122d2905d",
  "U2112_ERPBINDING": ".7642cfdfe11bd5637adc",
  "U2113_SUPPLIERINVITATION": ".37221efb4ddc2d0cd237",
  "U2118_RBBINDING": ".8d2b3207b2802d80ed9b",
  "U2132_AFFILIATION": ".ec26b790a437f1e8759c",
  "U2133_BUSINESSLINKS": ".c3a4a04a37c85acfc39f",
  "U2181": ".2012ee50b9f0a73f0a26",
  "U2211_SUBSCRIPTIONOFFER": ".279c808fb4ae87e7cc03",
  "U2212_SUBSCRIPTIONFILTER": ".3b147dc85ca5454cad4d",
  "U2213_SUBSCRIPTION": ".a220854222f02a97ad8c",
  "U4111_CREDITCARDADDITION": ".ec91d16cb771f6de7a20",
  "U4112_CREDITCARDDETAIL": ".f48ddfaafb626ae1ab96",
  "U4121_BANKACCOUNTADDITION": ".21bd9c5ca1f2e5409099",
  "U4122_BANKACCOUNTDETAIL": ".0a304e6f789fc40230fb",
  "U4303_ANONYMOUSTRANSACTION": ".4d0b88948e09ac9438f1",
  "U4401_PAYMENTGATEWAY": ".5f29defada5ef0df4408",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".3ab1b3cbffd67cd9f764",
  "U5000_RECEIVABLE": ".fc9b351f30f51a8d021a",
  "U5101_STATEMENTOFACCOUNT": ".edc0ddbcf83fdf6416ac",
  "U5102_INVOICE": ".2c4c52a30aeb36668515",
  "U5103_PADAGREEMENT": ".104e7c9716c1a2b6237b",
  "U5121_SENTINVOICE": ".7292d90980e427315e0d",
  "U5124_PGRECEIVEDPAYMENT": ".a08b05ff60acd0d283a9",
  "U5120_INVOICES": ".1f5accbc27a69afa4116",
  "U5123_PADAGREEMENTCONSOLE": ".284377e2c2906cb0d8cf",
  "U5132_PAYMENTRECONCILIATION": ".ba60bae5d8ad2f4a7c55",
  "U5133_BANKRECONCILIATION": ".7b451b7850225bdf9e45",
  "U6112_BUSINESSLINK": ".86dd48311c882b165ae5",
  "U6113_SUPPLIERRECORD": ".eac163c765e4666c7a9d",
  "U6211_PAYLOT": ".a9575a8bd26a216a5898",
  "U6311_PURCHASE": ".7ae7260456d927b149e0",
  "U2115_AFFILIATIONINVITATION": ".20ccd3cbfc98d2c65ce4",
  "U6100_PAYABLE": ".5ca179cc9f0377a6e9db",
  "U6131_SENTPAYMENT": ".ee27c3915b4dfee5831f",
  "U6191_SENTPAYMENTREPORT": ".d4d7fc205d6444d3f181",
  "U6221_SENTPAYMENT": ".2e0fd9d68adeb5a2d5f4",
  "U6422_INTERACSENTPAYMENT": ".db4563a8a9075095be07",
  "U6531_TRANSACTIONPAYMENT": ".d502d073862f84946c0e",
  "U5131_RECEIVEDPAYMENT": ".094ad3b6d12f48e0a114",
  "U5191_RECEIVEDPAYMENTREPORT": ".9f6afd0d5c91828b343a",
  "U2001_HOME": ".2acee1f60d503de89e23",
  "U2024_REPORTLOG": ".a3f5ee270df13c4d99cb",
  "U2111_INVOICEPORTALBINDING": ".f694cfa2509e3b9efd12",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".3e10e0963fd2255d8a4d",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".03961067b1690ae4cae1",
  "U6511_TRANSACTIONPARTY": ".930ebfcb783c4a27fcb6",
  "U6212_PAYROLLPARTY": ".2dbb20ea6d5a2ae10f5a",
  "U6121_EFTLOT": ".e3a4aa247f5221781cef",
  "U6412_INTERACFILE": ".32be3ad2a5022c42b302",
  "U6122_EFTAPPROVAL": ".8fb5ab824ff93f55e233",
  "U6512_TRANSACTIONLOT": ".e1ecd744328ea7307572",
  "U6123_EFTAPPROVAL": ".a53f94129c41bb5b5ce2",
  "U6123_EFTAPPROVALMOBILE": ".cd65618a3f1a668a50e1",
  "U2012_LOGIN": ".f5afb472b9c6692b1f5b",
  "U2000_PAYMENT": ".c61d6c1d6f96abb26d29",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".3c671c0da73d90a6936e",
  "U2000_PAYMENTMODEUPDATE": ".0b7b378f8f04673478d6",
  "U6114_TELUSBILLER": ".a6af82dd80af78522c30",
  "U4403_CREDITCARDTERMINAL": ".774ea531e047d7fcfeba",
  "U4404_INTERACTERMINAL": ".5981312087ceefa4deb5",
  "U2000_SAMPLECHEQUESELECTION": ".f6f7dfd02e17f3abe92e",
  "U2023_TUTORIALVIDEO": ".05a903b16e6b031d6185",
  "U2134_ORGANIZATIONBILLING": ".152e025c6f2dca018818",
  "U6414_BANKACCOUNTWALLET": ".20b3725c1e4149567616",
  "U2022_INSTANTACCOUNTVERIFICATION": ".fc985112652fd9c5accf",
  "U6532_PENDINGTRANSACTION": ".433364b6087c464a6157",
  "U4301_TRANSACTIONSSUMMARY": ".ba861aa6cd8b5e302d77",
  "U5111_SENTINVOICESTRACKING": ".7501a122677467334219",
  "U2026_NETPROMOTERSCORE": ".76f1a44ae838fe8a8979",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".9ab7b700be9a104a69ea",
  "U2000_INTRO": ".17880c4fcf545938c035",
  "U2000_ELECTRONICSIGNATURE": ".0eff1480d7400dbba083",
  "U2000_VERIFYEMAIL": ".6cc269f9036b74b2354a",
  "U2200_SUBSCRIPTION": ".e519e91acb6d1c5af7bd",
  "U2000_VERIFYIDENTITY": ".70432dadf248dba8b776",
  "U2000_ATTACHMENTVIEWER": ".24db62d614d507055546",
  "U2212_PLANLIST": ".cf0aaf3642d36daafa24",
  "U3002_TAXBILLSEARCHRESULT": ".0e2ad12df06d7ca55323"
}